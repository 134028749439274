import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { CodeSurfer, CodeSurferColumns, Step } from "code-surfer";
import { github, vsDark, nightOwl } from "@code-surfer/themes";
import Layout from "./src/Layout";
import LocalImage from "./src/LocalImage";
import './main.css';
export const theme = nightOwl;
export const _frontmatter = {};
const layoutProps = {
  theme,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Layout mdxType="Layout">
      <h1>{`Information patterns for microservice architecture`}</h1>
      <p>{`Toni Petrina `}<br />{`
Lead SRE `}<br />{`
Visma e-conomic `}<br /></p>
      <span style={{
        color: ""
      }}>SDD Conf 2024, London</span>
    </Layout>
    <hr></hr>
    <Layout style={{
      alignSelf: 'start'
    }} mdxType="Layout">
      <h2>{`Agenda`}</h2>
      <ul>
        <li parentName="ul">{`What changed with microservices?`}</li>
        <li parentName="ul">{`REST, eventual consistency and actors`}</li>
        <li parentName="ul">{`Where is our data?`}</li>
        <li parentName="ul">{`Recap`}</li>
      </ul>
    </Layout>
    <hr></hr>
    <h2>{`What this talk is not about?`}</h2>
    <ul>
      <li parentName="ul">{`Introduction to microservices`}</li>
      <li parentName="ul">{`Arguing for or against microservices`}</li>
    </ul>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-js",
          "metastring": "title=\"A procedural mindset\"",
          "title": "\"A",
          "procedural": true,
          "mindset\"": true
        }}>{`function (model: CreateUserModel) {
    // first we create a new user in our main DB
    user = db.insertUser(model);

    // user has accepted to join organization
    db.addToOrganization(user, model.organizationId);

    // then we connect it to our external CRM used for tracking
    crm.connectUser(user.id, model);

    // don't forget to send welcome or confirmation email
    mail.sendConfirmEmail(user.email);

    // set-up for compaign
    campaign.setNewUser(user.id, new Date());
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-js",
          "metastring": "title=\"Original requirement\"",
          "title": "\"Original",
          "requirement\"": true
        }}>{`function (model: CreateUserModel) {
    // first we create a new user in our main DB
    user = db.insertUser(model);

    // user has accepted to join organization
    db.addToOrganization(user, model.organizationId);
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-js",
          "metastring": "title=\"CRM integration added\"",
          "title": "\"CRM",
          "integration": true,
          "added\"": true
        }}>{`function (model: CreateUserModel) {
    // first we create a new user in our main DB
    user = db.insertUser(model);

    // user has accepted to join organization
    db.addToOrganization(user, model.organizationId);

    // then we connect it to our external CRM used for tracking
    crm.connectUser(user.id, model);
}

`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-js",
          "metastring": "title=\"New feature: confirmation email\"",
          "title": "\"New",
          "feature:": true,
          "confirmation": true,
          "email\"": true
        }}>{`function (model: CreateUserModel) {
    // first we create a new user in our main DB
    user = db.insertUser(model);

    // user has accepted to join organization
    db.addToOrganization(user, model.organizationId);

    // then we connect it to our external CRM used for tracking
    crm.connectUser(user.id, model);

    // don't forget to send welcome or confirmation email
    mail.sendConfirmEmail(user.email);
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-js",
          "metastring": "title=\"New feature: campaign tracker\"",
          "title": "\"New",
          "feature:": true,
          "campaign": true,
          "tracker\"": true
        }}>{`function (model: CreateUserModel) {
    // first we create a new user in our main DB
    user = db.insertUser(model);

    // user has accepted to join organization
    db.addToOrganization(user, model.organizationId);

    // then we connect it to our external CRM used for tracking
    crm.connectUser(user.id, model);

    // don't forget to send welcome or confirmation email
    mail.sendConfirmEmail(user.email);

    // set-up for compaign
    campaign.setNewUser(user.id, new Date());
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`Simple approach, complex outcomes`}</h1>
      <ul>
        <li parentName="ul">{`Transactions on system boundary`}
          <ul parentName="li">
            <li parentName="ul">{`Everything happens at once - all or nothing`}</li>
            <li parentName="ul">{`High coupling => system is in a consistent state`}</li>
          </ul>
        </li>
        <li parentName="ul">
          <span style={{
            color: "green"
          }}>&#9650;</span> All components share same availability
        </li>
        <li parentName="ul">
          <span style={{
            color: "green"
          }}>&#9650;</span> Procedure can be as complex as
possible, all data is available
        </li>
        <li parentName="ul">{`🔻 Rouge component can bring down the system`}</li>
      </ul>
    </Layout>
    <hr></hr>
    <h2>{`SQL schema...and probably code`}</h2>
    <LocalImage src="sql.png" mdxType="LocalImage" />
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`From monolith to services`}</h1>
      <p>{`The goal is to transform the monolithic codebase into a microservice one. `}<br />{`
How does that change the above code?`}</p>
    </Layout>
    <hr></hr>
    <h1>{`Microservices are:`}</h1>
    <Layout mdxType="Layout">
      <ul>
        <li parentName="ul">{`Highly maintainable and testable`}</li>
        <li parentName="ul">
          <span style={{
            color: "green"
          }}>Loosely coupled</span>
        </li>
        <li parentName="ul">{`Independently deployable`}</li>
        <li parentName="ul">
          <span style={{
            color: "green"
          }}>Organized around business capabilities</span>
        </li>
        <li parentName="ul">{`Owned by a small team`}</li>
      </ul>
      <p>{`(from `}<a parentName="p" {...{
          "href": "https://microservices.io"
        }}>{`microservices.io`}</a>{`)`}</p>
    </Layout>
    <hr></hr>
    <h1>{`Two problems`}</h1>
    <ol>
      <li parentName="ol">{`How do we `}<em parentName="li">{`perform`}</em>{` actions when components are distributed?`}</li>
      <li parentName="ol">{`How do we `}<em parentName="li">{`get`}</em>{` data out of distributed systems?`}</li>
    </ol>
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`Performing actions`}</h1>
      <ul>
        <li parentName="ul">{`When a user initiates action`}
          <ul parentName="li">
            <li parentName="ul">{`When is it done?`}</li>
            <li parentName="ul">{`How to handle failure?`}</li>
          </ul>
        </li>
      </ul>
    </Layout>
    <hr></hr>
    <h1>{`Within a service`}</h1>
    <LocalImage src="images/monolith.png" width="800px" mdxType="LocalImage" />
    <hr></hr>
    <h1>{`Sync architecture (request/response)`}</h1>
    <LocalImage src="images/request-driven.png" width="800px" mdxType="LocalImage" />
    <hr></hr>
    <h1>{`Single subsystem failure fails the request`}</h1>
    <LocalImage src="images/request-driven-error.png" width="800px" mdxType="LocalImage" />
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`Transactional boundary does not include external services`}</h1>
      <ul>
        <li parentName="ul">{`We can always roll-back changes made in the local db`}</li>
        <li parentName="ul">{`Use Outbox pattern within transaction for external services`}</li>
        <li parentName="ul">{`E.g. sending an e-mail mid-operation which gets rolled back`}</li>
      </ul>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`Transactions over multiple systems`}</h1>
      <ul>
        <li parentName="ul">{`Distributed Transactions`}</li>
        <li parentName="ul">{`Two-phase commit`}</li>
        <li parentName="ul">{`Saga`}</li>
      </ul>
    </Layout>
    <hr></hr>
    <h2>{`Events decouple subsystems and failures`}</h2>
    <LocalImage src="images/event-driven.png" width="800px" mdxType="LocalImage" />
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`Always emit events with Outbox pattern`}</h1>
      <p>{`Outbox pattern allows for:`}</p>
      <ul>
        <li parentName="ul">{`Persist events before sending them`}</li>
        <li parentName="ul">{`If transactional, simulates transaction with external services`}</li>
        <li parentName="ul">{`Allows retries and observability`}</li>
        <li parentName="ul">{`Ensures at least once deliverability`}</li>
      </ul>
    </Layout>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-js",
          "metastring": "title=\"Event code\"",
          "title": "\"Event",
          "code\"": true
        }}>{`function (model: CreateUserModel) {
    // First we create a new user in our main DB
    user = db.insertUser(model);

    // User has accepted to join organization
    db.addToOrganization(user, model.organizationId);

    // Add event to Outbox and process it later
    outbox.AddEvent(new UserCreatedEvent(user.Id));
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`Events are not a panacea`}</h1>
      <ul>
        <li parentName="ul">{`Switching from request/response to events might yield some new issues`}</li>
        <li parentName="ul">{`We knew that when the request finished, everything was "correct"`}</li>
        <li parentName="ul">{`Events will be delivered...when?`}</li>
      </ul>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`1. Eventual consistency`}</h1>
      <p>{`Issues:`}</p>
      <ul>
        <li parentName="ul">{`"User created, but can't login"`}</li>
        <li parentName="ul">{`Data changed, but UI doesn't reflect it`}</li>
      </ul>
      <p>{`Solve with:`}</p>
      <ul>
        <li parentName="ul">{`Sync write to read model in the same transaction`}</li>
        <li parentName="ul">{`Better UX (OK vs Accepted HTTP Status Code)`}</li>
        <li parentName="ul">{`Learn to live with it - the world is eventually consistent`}</li>
      </ul>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`2. Thundering herd and backpressure`}</h1>
      <blockquote>
  In computer science, the thundering herd problem occurs when a large number of
  processes or threads waiting for an event are awoken when that event occurs,
  but only one process is able to handle the event. When the processes wake up,
  they will each try to handle the event, but only one will win. All processes
  will compete for resources, possibly freezing the computer, until the herd is
  calmed down again.[1]
      </blockquote>
      <ul>
        <li parentName="ul">{`Subscribers must handle the load from producer`}
          <ul parentName="li">
            <li parentName="ul">{`Could you handle Twitter load on your service in real time?`}</li>
          </ul>
        </li>
      </ul>
      <p>{`Solve with:`}</p>
      <ul>
        <li parentName="ul">{`Backpressure and limiting on queue ingestion`}</li>
      </ul>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h2>{`3. Observability`}</h2>
      <LocalImage src="images/events-must-flow.jpg" width="800px" mdxType="LocalImage" />
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h2>{`3. Observability`}</h2>
      <p>{`Detect:`}</p>
      <ul>
        <li parentName="ul">{`Events not being processed`}</li>
        <li parentName="ul">{`Events being processed slowly`}</li>
      </ul>
      <p>{`Solve with:`}</p>
      <ul>
        <li parentName="ul">{`Synthetic tests to ensure a "stream" of events to process`}</li>
      </ul>
    </Layout>
    <hr></hr>
    <LocalImage src="images/control-system.png" width="800px" mdxType="LocalImage" />
    <hr></hr>
    <Layout mdxType="Layout">
      <h2>{`4. Temporal decoupling i.e. it is done when it is done`}</h2>
      <ul>
        <li parentName="ul">{`Not all operations "complete" at the same time. Depends on number of listeners and scale on those subsystems`}
          <ul parentName="li">
            <li parentName="ul">{`The slowest sub-operation slows down the entire operation`}</li>
          </ul>
        </li>
        <li parentName="ul">{`You might build a copy of a workflow engine`}</li>
        <li parentName="ul">{`Good reason to start using sagas in case of failures`}
          <ul parentName="li">
            <li parentName="ul">{`Or at least compensating operations`}</li>
            <li parentName="ul">{`Invest in customer support`}</li>
          </ul>
        </li>
      </ul>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h2>{`5. Distant failures`}</h2>
      <ul>
        <li parentName="ul">{`How to notify user when event handling fails?`}
          <ul parentName="li">
            <li parentName="ul">{`Can user even do anything about them?`}</li>
          </ul>
        </li>
        <li parentName="ul">{`Can we resolve them `}<em parentName="li">{`through support`}</em>{`?`}</li>
        <li parentName="ul">{`Need to show "ongoing" operations`}</li>
        <li parentName="ul">{`All UX issues become business issues`}</li>
        <li parentName="ul">{`At-least-once and idempotency ensure trivial retry without duplicating stuff`}</li>
      </ul>
    </Layout>
    <hr></hr>
    <h2>{`Message queue in front of each service`}</h2>
    <LocalImage src="images/event-driven-queue.png" width="800px" mdxType="LocalImage" />
    <hr></hr>
    <Layout mdxType="Layout">
      <h2>{`Queues are not the answer`}</h2>
      <ul>
        <li parentName="ul">{`Processing speed`}</li>
        <li parentName="ul">{`Parallelization`}</li>
        <li parentName="ul">{`Retries and error handling`}</li>
      </ul>
      <p>{`Think about:`}</p>
      <ul>
        <li parentName="ul">{`Message streams (e.g. Kafka)`}</li>
        <li parentName="ul">{`Poll model for recovery (e.g. Atom feeds)`}</li>
      </ul>
    </Layout>
    <hr></hr>
    <h2>{`Data requirements`}</h2>
    <ul>
      <li parentName="ul">{`Event was received, but does it contain enough data?`}</li>
      <li parentName="ul">{`Are API calls needed to fetch remaining data?`}</li>
    </ul>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "user_id": "baadf00d"
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "user_id": "baadf00d",
  "email": "user@example.com",
  "organization_id": "1"
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <Layout mdxType="Layout">
      <h2>{`Why "fat" events?`}</h2>
      <ul>
        <li parentName="ul">{`Event Carried State Transfer`}</li>
        <li parentName="ul">{`Less processing time`}</li>
        <li parentName="ul">{`Less pressure on original service`}</li>
      </ul>
      <p>{`Keep in mind:`}</p>
      <ul>
        <li parentName="ul">{`GDPR, security issues`}</li>
      </ul>
    </Layout>
    <hr></hr>
    <LocalImage src="images/Slim events require more communication.drawio.png" width="800px" mdxType="LocalImage" />
    <hr></hr>
    <Layout mdxType="Layout">
      <h2>{`Sagas`}</h2>
      <ul>
        <li parentName="ul">{`Coordinate steps across multiple services`}</li>
        <li parentName="ul">{`Rollback steps on failure`}</li>
        <li parentName="ul">{`Requires knowing all steps`}</li>
        <li parentName="ul">{`Requires knowing compensating action for all steps`}</li>
        <li parentName="ul">{`Two types: choreograpy and orchestration`}</li>
      </ul>
    </Layout>
    <hr></hr>
    <LocalImage src="images/Create_Order_Saga.png" width="1400px" mdxType="LocalImage" />
    <p>{`(from `}<a parentName="p" {...{
        "href": "https://microservices.io/patterns/data/saga.html"
      }}>{`microservices.io`}</a>{`)`}</p>
    <hr></hr>
    <Layout mdxType="Layout">
      <h2>{`Events and design`}</h2>
      <ul>
        <li parentName="ul">{`Events `}<em parentName="li">{`are`}</em>{` your API, design accordingly`}</li>
        <li parentName="ul">{`Centralized documentation and repository`}</li>
        <li parentName="ul">{`Versioning`}</li>
        <li parentName="ul">{`Granularity: CRUD or hyper detailed`}
          <ul parentName="li">
            <li parentName="ul"><inlineCode parentName="li">{`UserCreated`}</inlineCode>{`, `}<inlineCode parentName="li">{`UserBanned`}</inlineCode>{`, `}<inlineCode parentName="li">{`UserUnbanned`}</inlineCode>{`, `}<inlineCode parentName="li">{`UserBlocked`}</inlineCode></li>
            <li parentName="ul"><inlineCode parentName="li">{`UserCreated`}</inlineCode>{`, `}<inlineCode parentName="li">{`UserModified`}</inlineCode>{`, `}<inlineCode parentName="li">{`UserDeleted`}</inlineCode></li>
          </ul>
        </li>
      </ul>
    </Layout>
    <hr></hr>
    <h2>{`Options when performing actions across services`}</h2>
    <ol>
      <li parentName="ol">{`Sync communication (REST, gRPC)`}</li>
      <li parentName="ol">{`Async i.e. events (fire and forget)`}
        <ul parentName="li">
          <li parentName="ul">{`Message streams`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Events choreograpy`}</li>
      <li parentName="ol">{`Centralized process manager`}</li>
      <li parentName="ol">{`BFFs`}</li>
    </ol>
    <hr></hr>
    <h2>{`Anti-patterns when using events`}</h2>
    <ul>
      <li parentName="ul">{`Chatty events or chain events`}
        <ul parentName="li">
          <li parentName="ul">{`Essential workflows are implicit and hard to debug`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Coupling services with complex choreograpy`}
        <ul parentName="li">
          <li parentName="ul">{`Details might be lost, easy to break others, hard to roll out fixes`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Sagas for everything`}
        <ul parentName="li">
          <li parentName="ul">{`Logic leaks out into API Gateway or other unknown services`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Domain leaking`}
        <ul parentName="li">
          <li parentName="ul">{`Prefer losing context when receiving events`}</li>
        </ul>
      </li>
    </ul>
    <hr></hr>
    <Layout mdxType="Layout">
      <LocalImage src="images/tweet_651897353889259520_20240424_113650_via_10015_io.png" width="900px" mdxType="LocalImage" />
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <blockquote>
  <p>
    First Law of Distributed Object Design: "don't distribute your objects".
  </p>
  &mdash; Martin Fowler &nbsp;
  <LocalImage src="mf.jpg" width="100px" mdxType="LocalImage" />
      </blockquote>
    </Layout>
    <hr></hr>
    <h1>{`Data access`}</h1>
    <hr></hr>
    <h2>{`What about queries?`}</h2>
    <ul>
      <li parentName="ul">{`Querying data inside service is simple`}</li>
      <li parentName="ul">{`Filtering, sorting, pagination across services is harder`}</li>
      <li parentName="ul">{`We really like joining data`}
        <ul parentName="li">
          <li parentName="ul">{`Are those even relational databases?`}</li>
        </ul>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Types of reads`}</h2>
    <ul>
      <li parentName="ul">{`Querying data from external endpoint`}
        <ul parentName="li">
          <li parentName="ul">{`For UI, reports, public API`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Checking data as a part of the process`}
        <ul parentName="li">
          <li parentName="ul">{`Validation, verification`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Composing data as a part of the process`}
        <ul parentName="li">
          <li parentName="ul">{`Putting enough information into archive for easy lookup`}</li>
          <li parentName="ul">{`E.g. address at the point of purchase`}</li>
        </ul>
      </li>
    </ul>
    <hr></hr>
    <Layout mdxType="Layout">
      <h2>{`Can I use events for reading?`}</h2>
      <ul>
        <li parentName="ul">{`They need to cover all state changes`}
          <ul parentName="li">
            <li parentName="ul">{`You should be able to reconstruct a valid invariant`}</li>
          </ul>
        </li>
        <li parentName="ul">{`We shouldn't duplicate domain logic`}
          <ul parentName="li">
            <li parentName="ul">{`How to interpret events`}</li>
          </ul>
        </li>
      </ul>
      <p>{`Alternatives:`}</p>
      <ul>
        <li parentName="ul">{`Data replication e.g. Debezium`}</li>
        <li parentName="ul">{`Data sync microservice`}</li>
      </ul>
    </Layout>
    <hr></hr>
    <h2>{`Who owns data?`}</h2>
    <ul>
      <li parentName="ul">{`Static data/metadata (rarely changing, external data)`}
        <ul parentName="li">
          <li parentName="ul">{`VAT rules, countries, timezones, domain specific constant data`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Reference (master) data (usually business specific and highly important)`}
        <ul parentName="li">
          <li parentName="ul">{`Data commonly used across microservices`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Lookup data`}
        <ul parentName="li">
          <li parentName="ul">{`From one service to another`}</li>
        </ul>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Static data/metadata`}</h2>
    <ul>
      <li parentName="ul">{`Ship with the app as a package (Nuget, npm, gem)`}</li>
      <li parentName="ul">{`Data management microservice (cron update)`}</li>
    </ul>
    <hr></hr>
    <Layout mdxType="Layout">
      <LocalImage src="images/Sync service.drawio.png" width="900px" mdxType="LocalImage" />
    </Layout>
    <hr></hr>
    <h2>{`Reference data (master data)`}</h2>
    <ul>
      <li parentName="ul">{`Often changing data that is used in various services`}</li>
      <li parentName="ul">{`Need to have a canonical data owner`}</li>
      <li parentName="ul">{`Create caches next to the services that need them`}
        <ul parentName="li">
          <li parentName="ul">{`Sync via events`}</li>
          <li parentName="ul">{`Sync via cron job`}</li>
          <li parentName="ul">{`Anti-corruption layer to assure interesting data stays there`}</li>
        </ul>
      </li>
    </ul>
    <hr></hr>
    <h2>{`What about JOINs?`}</h2>
    <ul>
      <li parentName="ul">{`Filtering, Pagination, Search`}</li>
      <li parentName="ul">{`JOIN across servers is impossible`}</li>
      <li parentName="ul">{`Do it:`}
        <ol parentName="li">
          <li parentName="ol">{`in-app`}</li>
          <li parentName="ol">{`in API Gateway`}</li>
          <li parentName="ol">{`in a dedicated data lake/custom db`}</li>
        </ol>
      </li>
    </ul>
    <blockquote>
  Fetch customers 21-40 which have outstanding orders sorted by last order sent.
    </blockquote>
    <hr></hr>
    <h2>{`Could vs Should`}</h2>
    <ul>
      <li parentName="ul">{`You `}<strong parentName="li">{`could`}</strong>{` `}<inlineCode parentName="li">{`JOIN`}</inlineCode>{` in DB`}</li>
      <li parentName="ul">{`Is this `}<strong parentName="li">{`critical`}</strong>{` business functionality?`}</li>
    </ul>
    <hr></hr>
    <h2>{`Accidental complexity`}</h2>
    <ul>
      <li parentName="ul">{`Splitting systems does not remove the need for their interaction`}</li>
      <li parentName="ul">{`Required interactions become more complex`}</li>
      <li parentName="ul">{`Optional interactions complicate UX`}</li>
    </ul>
    <hr></hr>
    <h2>{`Service boundaries within a monolithic service`}</h2>
    <LocalImage src="images/service-boundary-1.png" height="80%" mdxType="LocalImage" />
    <hr></hr>
    <h2>{`Service boundaries in modules or microservices`}</h2>
    <LocalImage src="images/service-boundary-2.png" height="80%" mdxType="LocalImage" />
    <hr></hr>
    <h2>{`Service boundaries make operations explicit`}</h2>
    <LocalImage src="images/service-boundary-3.png" height="80%" mdxType="LocalImage" />
    <hr></hr>
    <h2>{`Proper modules and their dependencies`}</h2>
    <LocalImage src="images/service-boundary-4.png" height="80%" mdxType="LocalImage" />
    <hr></hr>
    <h2>{`Actual architecture`}</h2>
    <LocalImage src="images/service-boundary-5.png" height="80%" mdxType="LocalImage" />
    <hr></hr>
    <h2>{`Recap`}</h2>
    <ul>
      <li parentName="ul">{`Modeling distributed system is hard`}</li>
      <li parentName="ul">{`Use pain points to guide implementation and design`}</li>
      <li parentName="ul">{`Design and document events`}</li>
    </ul>
    <hr></hr>
    <Layout style={{
      gap: '1rem'
    }} mdxType="Layout">
      <h2>{`What's next?`}</h2>
      <ul>
        <li parentName="ul">{`Testing...`}</li>
      </ul>
      <img style={{
        marginTop: "0",
        height: '50vh'
      }} src="https://media.giphy.com/media/WodKaNr3eh7FudzZVb/giphy.gif" role="presentational" />
    </Layout>
    <hr></hr>
    <Layout style={{
      alignSelf: 'start'
    }} mdxType="Layout">
      <h1>{`Thank you!`}</h1>
      <p>{`Toni Petrina `}<br />{`
twitter.com/tonipetrina1 `}<br />{`
github.com/tpetrina`}</p>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      