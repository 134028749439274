import { CodeSurfer, CodeSurferColumns, Step } from "code-surfer";
import { github, vsDark, nightOwl } from "@code-surfer/themes";
import Layout from "../../../../../../src/Layout";
import LocalImage from "../../../../../../src/LocalImage";
import "../../../../../../main.css";
import * as React from 'react';
export default {
  CodeSurfer: CodeSurfer,
  CodeSurferColumns: CodeSurferColumns,
  Step: Step,
  github: github,
  vsDark: vsDark,
  nightOwl: nightOwl,
  Layout: Layout,
  LocalImage: LocalImage,
  React: React
};